import React, { useState } from "react"
import PropTypes from "prop-types"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import styles from "./ImageBlock.module.scss"
import "react-bnb-gallery/dist/style.css"

const ImageBlock = props => {
  const [isOpen, setIsOpen] = useState(false)

  const handleKeyDown = e => {
    // allows the ReactBnbGallery to be triggered by Enter
    if (e.keyCode === 13) {
      setIsOpen(true)
    }
  }

  return (
    <div
      className={`w-full p-8 flex items-center justify-center
            ${props.color === "blue" ? styles.blue : ""}
            ${props.color === "pink" ? styles.pink : ""}
            ${props.color === "green" ? styles.green : ""}
            ${props.color === "orange" ? styles.orange : ""}
           
           `}
    >
      
        <img
          src={props.image}
          onClick={() => setIsOpen(true)}
          tabIndex={0}
          onKeyDown={e => handleKeyDown(e)}
          className={`cursor-pointer ${styles.imageblock} ${props.shadow ? 'shadow-md' : ""}`}
          alt={props.alt ? props.alt : "Image Block Screenshot"}
        />
      

      {isOpen && (
        <Lightbox
          mainSrc={props.zoomImage ? props.zoomImage : props.image}
          animationDuration={150}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom={true}
          imageTitle={props.title}
          imagePadding={40}
        />
      )}
    </div>
  )
}

ImageBlock.propTypes = {
  color: PropTypes.oneOf(["blue", "pink", "green", "orange"]),
  image: PropTypes.string.isRequired,
  zoomImage: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  shadow: PropTypes.bool,
}

export default ImageBlock
